import { styled } from '@mui/material/styles';
import Image from 'next/image';
import { AssetFile } from 'contentful';

interface SealWrapperProps {
  height: number;
  width: number;
}
const SealWrapper = styled('div')<SealWrapperProps>(({ height, width }) => ({
  height: height,
  width: width,
  position: 'relative',
  marginBottom: '10px',
}));

interface SealProps {
  url: string | AssetFile | undefined;
  alt: string;
  height: number;
  width: number;
}
export const Seal = (props: SealProps) => {
  const { url, alt, height, width } = props;

  return (
    <SealWrapper height={height} width={width}>
      <Image src={url!.toString()} alt={alt} fill priority={true} />
    </SealWrapper>
  );
};
